<template>
  <v-app>
    <dashboard-core-app-bar/>

    <dashboard-core-drawer/>

    <dashboard-core-view/>
  </v-app>
</template>

<script>

  import { mapActions, mapState } from 'vuex'
  import { Client } from '@pusher/push-notifications-web/dist/push-notifications-esm'

  const beamsClient = new Client({
    instanceId: '644154b0-a21a-450a-97db-7b04a785a500',
  })
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreView: () => import('./components/core/View'),
    },

    data: () => ({
      expandOnHover: true,
    }),
    computed: {
      ...mapState('perfil', ['empresa']),
    },
    methods: {
      ...mapActions('perfil', ['fetchUser', 'fetchEmpresa']),
    },
    async mounted () {
      await this.fetchUser()
      await this.fetchEmpresa()
      beamsClient.start()
        .then(() => beamsClient.addDeviceInterest(this.empresa.codigo))
    },
  }
</script>
